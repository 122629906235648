import Vue from 'vue'
import Router from 'vue-router'
import Auth from '@okta/okta-vue'
import OktaAuth from '@okta/okta-auth-js'
import get from 'lodash/get'
import { hasAccess } from '@/services/RoleService'

// preloaded routes
import TrialsPage from '@/views/entities/trial/TrialsPage'

// This fixes the NavigationDuplicated issue (STRP-2365)
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => {
    return err.name === "NavigationDuplicated" ? Promise.resolve() : Promise.reject(err);
  })
};
Vue.use(Router)
Vue.use(Auth, {
  issuer: process.env.VUE_APP_OKTA_CLIENT_ISSUER,
  client_id: process.env.VUE_APP_OKTA_CLIENT_ID,
  redirect_uri: process.env.VUE_APP_SERVER_URL,
  scope: 'openid profile email roles',
  cookies:
  {
    secure: true,
  },
  storageManager: {
    token: {
        storageType: 'sessionStorage',
    }
  } ,
  services: {
      autoRenew: true,
      autoRemove: true,
      syncStorage: true,
  },
})
const authClient = new OktaAuth({
  url: process.env.VUE_APP_SERVER_URL.split('/implicit/callback')[0],
  clientId: process.env.VUE_APP_OKTA_CLIENT_ID,
  issuer: process.env.VUE_APP_OKTA_CLIENT_ISSUER,
  redirectUri: process.env.VUE_APP_SERVER_URL,
  scope: 'openid profile email roles',
  cookies: {
    secure: true,
  },
  storageManager: {
    token: {
        storageType: 'sessionStorage',
    }
  } ,
  services: {
      autoRenew: true,
      autoRemove: true,
      syncStorage: true,
  },
})

const routes = [
  {
    path: '/',
    name: 'redirect',
    beforeEnter: beforeHomeRoute,
  },
  {
    path: '*',
    redirect: '/not-found',
  },
  {
    // login
    path: '/login',
    name: 'login',
    component: () => import('./views/utils/WarningPage'),
    async beforeEnter(to, from, next) {
      if (await router.app.$auth.isAuthenticated()) {
        next('/')
      } else {
        next()
      }
    },
    meta: {
      title: 'Warning',
    },
  },
  {
    // logout
    path: '/logout',
    name: 'logout',
    async beforeEnter(to, from, next) {
      try {
        await router.app.$auth.logout()
        await router.app.$auth.isAuthenticated()
        next('/login')
      } catch (e) {
        next('/login')
      }
    },
  },
  {
    // callback
    path: '/implicit/callback',
    beforeEnter: beforeCallbackRoute,
    component: Auth.handleCallback(),
    meta: {
      title: 'Logging in...',
    },
  },

  {
    // home
    path: '/home',
    name: 'home',
    beforeEnter: requireAuth,
    component: () => import('./views/home/HomePage'),
    meta: {
      title: 'Home',
      header: true,
    },
  },
  {
    // studies
    path: '/studies',
    name: 'studies',
    beforeEnter: requireAuth,
    component: TrialsPage,
    props: true,
    meta: {
      title: 'Studies',
      header: true,
    },
  },
  {
    // sites
    path: '/organizations',
    name: 'organizations',
    beforeEnter: requireAuth,
    component: () => import('./views/entities/site/SitesPage'),
    props: true,
    meta: {
      title: 'Organizations',
      header: true,
    },
  },
  {
    // investigators
    path: '/investigators',
    name: 'investigators',
    beforeEnter: requireAuth,
    component: () => import('./views/entities/investigator/InvestigatorsPage'),
    props: true,
    meta: {
      title: 'Investigators',
      header: true,
    },
  },
  {
    // participants
    path: '/participants',
    name: 'participants',
    beforeEnter: requireAuth,
    component: () => import('./views/entities/participant/ParticipantsPage'),
    props: true,
    meta: {
      title: 'Participants',
      header: true,
      props: true,
    },
  },
  {
    // container
    path: '/cancer-centers',
    name: 'containers',
    beforeEnter: requireAuth,
    component: () => import('./views/entities/container/ContainerPage'),
    props: true,
    meta: {
      title: 'Cancer Centers',
      header: true,
    },
  },
  {
    // study
    path: '/studies/:id',
    name: 'study',
    beforeEnter: requireAuth,
    component: () => import('./views/entities/EntityPage'),
    meta: {
      entity: 'study',
      title: ':id',
    },
  },
  {
    // site
    path: '/organizations/:id',
    name: 'site',
    beforeEnter: requireAuth,
    component: () => import('./views/entities/EntityPage'),
    meta: {
      entity: 'site',
      title: ':id',
    },
  },
  {
    // investigator
    path: '/investigators/:id',
    name: 'investigator',
    beforeEnter: requireAuth,
    component: () => import('./views/entities/EntityPage'),
    meta: {
      entity: 'investigator',
      title: ':id',
    },
  },
  {
    // reports
    path: '/reports',
    name: 'reports',
    beforeEnter: requireAuth,
    component: () => import('./views/reports/ReportsPage'),
    meta: {
      title: 'Reports',
      header: true,
    },
  },
  {
    // not-found
    path: '/not-found',
    name: 'not-found',
    component: () => import('./views/utils/ErrorPage'),
    meta: {
      title: 'Not Found',
      type: 'not-found',
    },
  },
  {
    // unauthorized
    path: '/unauthorized',
    name: 'unauthorized',
    component: () => import('./views/utils/ErrorPage'),
    meta: {
      title: 'Unauthorized',
      type: 'unauthorized',
    },
  },
  {
    // test
    path: '/test',
    name: 'test',
    component: () => import('./views/TestPage'),
    meta: {
      title: 'Test',
    },
  },
  {
    // help
    path: '/help',
    redirect: '/documentation',
  },
  {
    // ccode
    path: '/ccode/:id',
    name: 'ccode',
    beforeEnter: (to) =>
      openTab(
        `https://evsexplore.nci.nih.gov/evsexplore/hierarchy/ncit/${
          to.params.id
        }`
      ),
  },
  {
    // cde
    path: '/cde/:id',
    name: 'cde',
    beforeEnter: (to) =>
      openTab(
        `https://cdebrowser.nci.nih.gov/cdebrowserClient/cdeBrowser.html#/search?publicId=${
          to.params.id
        }&version=9.0`
      ),
  },
  {
    // ctgov
    path: '/ctgov/:id',
    name: 'ctgov',
    beforeEnter: (to) =>
      openTab(`https://clinicaltrials.gov/ct2/show/${to.params.id}`),
  },

  {
    // swagger
    path: '/swagger',
    name: 'swagger',
    beforeEnter: requireAuth,
    component: () => import('./views/swagger/Swagger.vue'),
    meta: {
      title: 'Swagger',
      header: true,
    },
  },
]

const router = new Router({
  mode: 'history',
  base: process.env.VUE_APP_BASE_HREF,
  routes,
  linkActiveClass: 'active',
  scrollBehavior(to, from, savedPosition) {
    return savedPosition ? savedPosition : { x: 0, y: 0 }
  },
})

// beforeEach
router.beforeEach((to, from, next) => {
  let title = get(to, 'meta.title', '')
  let displayTitle = title.includes(':')
    ? to.params[title.split(':')[1]]
    : title
  document.title = displayTitle ? `${displayTitle} - STRAP` : 'STRAP'

  router.app.$store.dispatch('intro/setPageName', to.name)

  next()
})

// helper functions
async function requireAuth(to, from, next, pageTo) {
  const isAuthenticated = await router.app.$auth.isAuthenticated()
  const section = get(sectionMap, to.name, to.name)

  if (process.env.VUE_APP_MOCK === 'true') {
    next(pageTo)
    return
  }

  if (isAuthenticated) {
    if (hasAccess(section)) {
      next(pageTo)
    } else {
      next('/unauthorized')
    }
  } else {
    next('/logout')
    router.app.$store.dispatch('toaster/start', {
      message: 'Please log in to use STRAP',
      icon: 'sign-out-alt',
    })
  }
}
async function beforeHomeRoute(to, from, next) {
  const roles = getRoles()
  const pageTo =
    roles.includes('CANCER_CENTER') || roles.includes('REVIEWER')
      ? '/reports'
      : '/home'

  requireAuth(to, from, next, pageTo)
}
async function beforeCallbackRoute(to, from, next) {
  // if the user is logged in, route them home
  if (await router.app.$auth.isAuthenticated()) {
    next('/')
  }
  // if there is an error returned in by okta
  else if (window.location.href.search('error') !== -1) {
    next('/unauthorized')
  }
  // if they have a token in the url, use the okta callback component
  else if (window.location.href.search('id_token') !== -1) {
    next()
  } else {
    authClient.token.getWithRedirect({
      redirect_uri: process.env.VUE_APP_SERVER_URL,
      responseType: ['id_token', 'token'],
      scopes: ['openid', 'profile', 'email', 'roles'],
    })
  }
}

function openTab(url) {
  window.open(url, '_blank')
}
function getRoles() {
  const oktaStorage = JSON.parse(localStorage.getItem('okta-token-storage'))
  return get(oktaStorage, 'idToken.claims.roles', [])
}
const sectionMap = {
  study: 'studies',
  site: 'organizations',
  investigator: 'investigators',
}

export default router
